var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.Inicio)?_c('div',[_c('div',{staticClass:"d-none d-md-block"},[_c('v-carousel',{attrs:{"hide-delimiters":"","cycle":true,"height":"100%","show-arrows":false},model:{value:(_vm.valor),callback:function ($$v) {_vm.valor=$$v},expression:"valor"}},_vm._l((_vm.Inicio.carrusel),function(ref,i){
var imagen = ref.imagen;
var texto = ref.texto;
return _c('v-carousel-item',{key:i},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-img',{attrs:{"height":"100%","src":imagen,"contain":""}},[_c('div',{staticClass:"fila-texto"},[_c('h3',{staticClass:"white--text",staticStyle:{"line-height":"45px"},domProps:{"innerHTML":_vm._s(texto)}}),_c('img',{attrs:{"src":_vm.Inicio.cuadro_recortado}})]),_vm._l((_vm.botones),function(ref,i){
var posicion = ref.posicion;
var icono = ref.icono;
return _c('button',{key:i,staticClass:"boton",attrs:{"posicion":posicion},on:{"click":function($event){return _vm.cambiarPosicion(posicion)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v(_vm._s(icono))])],1)})],2)],1)],1)}),1),_c('div',{staticClass:"bloque-servicios white"},[_c('v-row',{staticClass:"justify-center align-center align-items-center centrado"},_vm._l((_vm.Inicio.servicios),function(ref,i){
var texto = ref.texto;
var imagen = ref.imagen;
return _c('v-col',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],key:i,attrs:{"cols":"4","card":"true"},on:{"click":function($event){return _vm.dirigir_a("servicios")}}},[_c('v-card',{staticClass:"card justify-center align-items-center",staticStyle:{"cursor":"pointer!important"}},[_c('img',{attrs:{"src":imagen}}),_c('v-row',{staticClass:"texto justify-center align-items-center"},[_c('h3',{staticClass:"azul--text"},[_vm._v(_vm._s(texto))])])],1)],1)}),1)],1),_c('div',{staticClass:"bloque-ubicacion azul"},[_c('div',{staticClass:"fila-titulo align-items-center justify-center"},[_c('div',{staticClass:"cuadro-rojo naranja mr-3"}),_c('h2',{staticClass:"white--text font-weight-bold"},[_vm._v(_vm._s(_vm.Inicio.titulo))]),_c('div',{staticClass:"cuadro-rojo naranja ml-3"})]),_c('div',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],staticClass:"fila-ubicacion"},[_c('div',{staticClass:"columna-imagen"},[_c('v-carousel',{attrs:{"hide-delimiters":"","cycle":true,"show-arrows":false},model:{value:(_vm.valor_contacto),callback:function ($$v) {_vm.valor_contacto=$$v},expression:"valor_contacto"}},_vm._l((_vm.Inicio.contacto_arreglo),function(imagen,i){return _c('v-carousel-item',{key:i},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-img',{attrs:{"width":"600","height":"287","src":imagen,"contain":""}})],1)],1)}),1),_vm._l((_vm.botones_contacto),function(ref,i){
var posicion = ref.posicion;
var icono = ref.icono;
return _c('button',{key:i,staticClass:"boton_contacto",attrs:{"posicion":posicion},on:{"click":function($event){return _vm.cambiarPosicionContacto(posicion)}}},[_c('v-icon',{staticClass:"white--text"},[_vm._v(_vm._s(icono))])],1)})],2),_c('div',{staticClass:"columna-ubicacion"},_vm._l((_vm.Inicio.contacto),function(ref,i){
var icono = ref.icono;
var línea_1 = ref.línea_1;
var línea_2 = ref.línea_2;
return _c('div',{key:i,staticClass:"fila-contacto"},[_c('v-row',[_c('img',{staticClass:"mr-2",attrs:{"src":icono,"width":"30","indice":i}}),_c('h3',{staticClass:"white--text font-weight-light"},[_vm._v(_vm._s(línea_1))])]),_c('v-row',{staticClass:"ml-4"},[_c('h3',{staticClass:"white--text"},[_vm._v(_vm._s(línea_2))])])],1)}),0)])])],1),_c('div',{staticClass:"d-md-none"},[_c('v-carousel',{attrs:{"hide-delimiters":"","cycle":true,"height":"auto","show-arrows":false},model:{value:(_vm.valor),callback:function ($$v) {_vm.valor=$$v},expression:"valor"}},_vm._l((_vm.Inicio.carruselmovil),function(ref,i){
var imagen = ref.imagen;
var textomovil = ref.textomovil;
return _c('v-carousel-item',{key:i},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-img',{attrs:{"src":imagen,"height":"auto"}},[_c('div',{staticClass:"fila-texto-movil"},[_c('p',{staticClass:"font-weight-regular white--text d-block d-sm-none",staticStyle:{"line-height":"4px"},domProps:{"innerHTML":_vm._s(textomovil)}}),_c('p',{staticClass:"font-weight-regular white--text d-none d-sm-block d-md-none",staticStyle:{"line-height":"15px","font-size":"30px"},domProps:{"innerHTML":_vm._s(textomovil)}}),_c('img',{staticClass:"d-block d-sm-none",attrs:{"src":_vm.Inicio.cuadro_recortado}})])])],1)],1)}),1),_c('div',{staticClass:"bloque-servicios-movil white",attrs:{"celular":"true"}},[_c('v-row',{staticClass:"justify-center align-center centrado-movil"},_vm._l((_vm.Inicio.servicios),function(ref,i){
var texto = ref.texto;
var imagen = ref.imagen;
return _c('v-col',{directives:[{name:"scrollanimation",rawName:"v-scrollanimation"}],key:i,staticClass:"mt-12",attrs:{"cols":"9","sm":"5","md":"12","card":"true"},on:{"click":function($event){return _vm.dirigir_a("servicios")}}},[_c('v-card',{staticClass:"card justify-center mt-2",staticStyle:{"cursor":"pointer!important"}},[_c('img',{attrs:{"src":imagen}}),_c('v-row',{staticClass:"justify-center"},[_c('v-col',{staticClass:"texto",attrs:{"cols":"8"}}),_c('p',{staticClass:"azul--text font-weight-bold"},[_vm._v(_vm._s(texto))])],1)],1)],1)}),1)],1),_c('div',{staticClass:"bloque-ubicacion-movil azul"},[_c('div',{staticClass:"fila-titulo align-items-center justify-center"},[_c('div',{staticClass:"cuadro-rojo naranja mr-3"}),_c('h2',{staticClass:"white--text font-weight-bold"},[_vm._v(_vm._s(_vm.Inicio.titulo))]),_c('div',{staticClass:"cuadro-rojo naranja ml-3"})]),_c('v-carousel',{staticClass:"mt-3",attrs:{"hide-delimiters":"","cycle":true,"show-arrows":false},model:{value:(_vm.valor_contacto),callback:function ($$v) {_vm.valor_contacto=$$v},expression:"valor_contacto"}},_vm._l((_vm.Inicio.contacto_arreglo),function(imagen,i){return _c('v-carousel-item',{key:i},[_c('v-container',{staticClass:"pa-0",attrs:{"fluid":""}},[_c('v-img',{attrs:{"height":"287","src":imagen}})],1)],1)}),1),_vm._l((_vm.Inicio.contacto),function(ref,i){
var icono = ref.icono;
var línea_1 = ref.línea_1;
var línea_2 = ref.línea_2;
return _c('v-row',{key:i,staticClass:"ml-3 mr-3"},[_c('v-container',{staticClass:"ma-3"},[_c('v-row',[_c('img',{staticClass:"mr-2",attrs:{"src":icono,"width":"34","height":i===0 ? 40 : 56}}),_c('v-col',{staticClass:"ml-2"},[_c('v-row',[_c('h3',{staticClass:"white--text font-weight-light"},[_vm._v(_vm._s(línea_1))])]),_c('v-row',[_c('h3',{staticClass:"white--text"},[_vm._v(_vm._s(línea_2))])])],1)],1)],1)],1)})],2)],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }