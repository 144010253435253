<template lang="pug">
div(v-if='Inicio')
  //-Pc
  .d-none.d-md-block
    v-carousel(v-model='valor'  hide-delimiters :cycle='true' height='100%' :show-arrows='false')
      v-carousel-item(v-for='{imagen, texto}, i in Inicio.carrusel' :key='i')
        v-container(fluid).pa-0 
          v-img(  height='100%' :src='imagen' contain )
            .fila-texto
              h3(v-html='texto' style='line-height : 45px').white--text
              img(:src='Inicio.cuadro_recortado' )
            button(v-for='{posicion, icono}, i in botones' :key='i' :posicion='posicion' 
            @click='cambiarPosicion(posicion)').boton
              v-icon.white--text {{icono}}
    .bloque-servicios.white
      v-row.justify-center.align-center.align-items-center.centrado
        v-col(cols='4' v-for='{texto, imagen}, i in Inicio.servicios' :key='i' 
        @click='dirigir_a("servicios")' v-scrollanimation card='true')
          v-card(style='cursor: pointer!important').card.justify-center.align-items-center
            img(:src='imagen')
            v-row.texto.justify-center.align-items-center 
              h3.azul--text {{texto}}
    .bloque-ubicacion.azul
      .fila-titulo.align-items-center.justify-center
        .cuadro-rojo.naranja.mr-3
        h2.white--text.font-weight-bold {{Inicio.titulo}}
        .cuadro-rojo.naranja.ml-3
      .fila-ubicacion(v-scrollanimation)
        .columna-imagen
          v-carousel(v-model='valor_contacto'  hide-delimiters :cycle='true' :show-arrows='false')
            v-carousel-item(v-for='imagen, i in Inicio.contacto_arreglo' :key='i')
              v-container(fluid).pa-0 
                v-img(  width='600' height='287' :src='imagen' contain )
          button(v-for='{posicion, icono}, i in botones_contacto' :key='i' :posicion='posicion' 
          @click='cambiarPosicionContacto(posicion)').boton_contacto
            v-icon.white--text {{icono}}
        .columna-ubicacion
          .fila-contacto(v-for='{icono, línea_1, línea_2}, i in Inicio.contacto' :key='i')
              v-row 
                  img(:src='icono' width='30' :indice='i').mr-2
                  h3.white--text.font-weight-light {{línea_1}}
              v-row.ml-4
                h3.white--text {{línea_2}}
  //-Móvil
  .d-md-none
    v-carousel(v-model='valor'  hide-delimiters :cycle='true'  height='auto' :show-arrows='false')
      v-carousel-item(v-for='{imagen, textomovil}, i in Inicio.carruselmovil' :key='i')
        v-container(fluid).pa-0 
          v-img(  :src='imagen' height='auto' )
            .fila-texto-movil
              p(v-html='textomovil' style='line-height : 4px').font-weight-regular.white--text.d-block.d-sm-none
              p(v-html='textomovil' style='line-height : 15px; font-size: 30px').font-weight-regular.white--text.d-none.d-sm-block.d-md-none
              img(:src='Inicio.cuadro_recortado' ).d-block.d-sm-none
    .bloque-servicios-movil.white(celular='true')
      v-row.justify-center.align-center.centrado-movil
        v-col(cols='9' sm='5' md='12' v-for='{texto, imagen}, i in Inicio.servicios' :key='i'
        @click='dirigir_a("servicios")' v-scrollanimation card='true').mt-12
          v-card(style='cursor: pointer!important').card.justify-center.mt-2
            img(:src='imagen')
            v-row.justify-center
              v-col(cols='8').texto
              p.azul--text.font-weight-bold {{texto}}
              
    .bloque-ubicacion-movil.azul
      .fila-titulo.align-items-center.justify-center
        .cuadro-rojo.naranja.mr-3
        h2.white--text.font-weight-bold {{Inicio.titulo}}
        .cuadro-rojo.naranja.ml-3
      v-carousel(v-model='valor_contacto'  hide-delimiters :cycle='true' :show-arrows='false').mt-3
        v-carousel-item(v-for='imagen, i in Inicio.contacto_arreglo' :key='i')
          v-container(fluid).pa-0 
            v-img(  height='287' :src='imagen' )
      
      v-row(v-for='{icono, línea_1, línea_2}, i in Inicio.contacto' :key='i').ml-3.mr-3
        v-container.ma-3
          v-row
            img(:src='icono' width='34' :height='i===0 ? 40 : 56' ).mr-2
            v-col.ml-2
              v-row
                h3.white--text.font-weight-light {{línea_1}}
              v-row
                h3.white--text {{línea_2}}
</template>
<script>
export default {
  data: ()=>({
    valor_contacto: 0,
    valor: 0,
    botones: [
      {posicion: 'izquierdo', icono: 'fas fa-chevron-left'},
      {posicion: 'derecho', icono: 'fas fa-chevron-right'},
    ],
    botones_contacto: [
      {posicion: 'izquierdo', icono: 'far fa-arrow-alt-circle-left'},
      {posicion: 'derecho', icono: 'far fa-arrow-alt-circle-right'},
    ],
  }),
  methods: {
    cambiarPosicion(posicion){
      if(posicion==='izquierdo') if(this.valor===0) 
        this.valor = this.Inicio.carrusel.length -1 
        else this.valor = this.valor -1
      else if(this.valor===this.Inicio.carrusel.length -1) 
        this.valor = 0
        else this.valor = this.valor +1 
    },
    cambiarPosicionContacto(posicion){
      if(posicion==='izquierdo') if(this.valor_contacto===0) 
        this.valor_contacto = this.Inicio.contacto_arreglo.length -1 
        else this.valor_contacto = this.valor_contacto -1
      else if(this.valor_contacto===this.Inicio.contacto_arreglo.length -1) 
        this.valor_contacto = 0
        else this.valor_contacto = this.valor_contacto +1
    },
  },
}
</script>

<style lang="sass" scoped>
.fila-texto-movil
  position: absolute
  display: flex
  top: 2.5em
  left:1.5em
  font-size: 14px
  img
    position: absolute
    display: flex
    width: 30px
    height: 30px
    top: 4.5em
    left: -1em
.bloque-servicios-movil
  display: flex
  flex-direction: row
  padding-bottom: 40px
  padding-top: 40px
  .centrado-movil
    display: flex
    flex-direction: row
    .card
      width: auto
      height: 150px
      border: 2px solid #011f43
      border-radius: 20px!important
      .texto
        margin-top: 3em
    img
      position: absolute
      top: -40%
      left: 34.8%
      width: 90px
      height: 90px
.boton
  position: absolute
  width: 50px
  height: 50px
  border-radius: 15px
  top: 43%
  background-color: #e74212
  &[posicion='izquierdo']
    left: 2%
  &[posicion='derecho']
    right: 2%
.fila-texto
  position: absolute
  display: flex
  top: 23%
  left: 13%
  img
    position: absolute
    display: flex
    width: 80px
    height: 70px
    top: 90%
    left: -2em
.bloque-servicios
  display: flex
  flex-direction: row
  padding-top: 90px
  padding-bottom: 40px
  width: 100%
  height: 580px
  .centrado
    padding-right: 15%
    padding-left: 17%
    .card
      width: 300px
      height: 150px
      border: 2px solid #011f43
      border-radius: 20px!important
      .texto
        display: flex
        justify-content: center
        text-align: center!important
        position: absolute
        width: 200px
        top: 50%
        left: 22%
      img
        position: absolute
        top: -40%
        left: 34.8%
        width: 90px
        height: 90px




.bloque-ubicacion
  display: flex
  flex-direction: column
  padding-top: 30px
  padding-bottom: 80px
  width: 100%
  height: 430px
  .fila-titulo
    display: flex
    justify-flex: center
    justify-content: center
    align-items: center
    .cuadro-rojo
      width: 5px
      height: 5px
  .fila-ubicacion
    padding-top: 20px
    padding-right: 40px
    padding-left: 40px
    display: flex
    flex-direction: row
    .columna-imagen
      padding-left: 140px
      .boton_contacto
        position: absolute
        width: 50px
        height: 50px
        border-radius: 50%
        top: 28.8%
        background-color: transparent 
        border: 2px solid #e74212
        &[posicion='izquierdo']
          left: 6.8%
        &[posicion='derecho']
          right: 44.8%
    .columna-ubicacion
      padding-top: 106px
      padding-left: 190px
      flex-direction: column
      .fila-contacto
        padding-bottom: 40px
        flex-direction: column
        img
          &[indice='0']
            height: 25px
            width: 25px
          &[indice='1']
            width: 18px



.bloque-ubicacion-movil
  display: flex
  flex-direction: column
  padding-top: 30px
  padding-bottom: 80px
  width: 100%
  height: 730px
  .fila-titulo
    display: flex
    justify-flex: center
    justify-content: center
    align-items: center
    .cuadro-rojo
      width: 5px
      height: 5px
  .fila-ubicacion
    padding-top: 20px
    padding-right: 40px
    padding-left: 40px
    display: flex
    flex-direction: row
    .columna-imagen
      padding-left: 140px
  .boton_contacto
    position: absolute
    width: 50px
    height: 50px
    border-radius: 50%
    top: 63.4%!important
    right: 44.8%
  .fila-contacto
    padding-bottom: 40px
    flex-direction: column
    img
      &[indice='0']
        height: 25px
        width: 25px
      &[indice='1']
        width: 18px
.before-enter
  opacity: 0
  transform: translatex(-100%)
  transition: all 1.5s ease-out 
  &[card='true']
    transform: scale(.5) rotateZ(-25deg)
    transition: all 1s ease-out  
.enter
  opacity: 1
  transform: translateX(0px)
  &[card='true']
    transform: scale(1) rotateZ(0deg)
</style>